import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import LanguageSelector from "./LanguageSelector"
import CurrencySelector from "./CurrencySelector"
import LoginModal from "../../ModalLogin"
import { withUserContext } from "../../../context/UserContext"
import Popover, { ArrowContainer } from "react-tiny-popover"
// import { Link } from "gatsby"
import {
  MicroStar,
  MicroCombo,
} from "@dadoagency/gatsby-plugin-trustpilot-widget"
import TrustPilotWidget from "../TrustPilotWidget"
import StaticReviewSection from "../../StaticReviewSection"
import { isBrowser } from "../../../context/ApolloContext"
import { useLocation } from "@reach/router"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: black;
  color: ${props => props.theme.colors.black.light};
  min-height: 34px;

  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  padding: 3px 16px;
  position: relative;
  z-index: 1;

  @media (max-width: 850px) {
    flex-direction: column;
    min-height: 34px;
    padding: 3px 0px;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 1200px) {
    display: none;
  }
`

const CenterWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #fff;
  letter-spacing: normal;

  .op-boutique {
    font-size: 12px;
    color: #fff;
    font-family: "GothamLight";
    letter-spacing: 0;
    font-weight: bold;
  }
  .opco-message {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    white-space: normal;
    overflow: hidden;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px;
    -webkit-animation-duration: 14s;
    animation-duration: 14s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: translate3d(0, 0, 1);
    transform: translate3d(0, 0, 1);
    will-change: transform, opacity;
  }
  .opco-message a {
    color: #fff;
    text-decoration: underline;
  }
  .op-co-message1 {
    -webkit-animation-name: first-message;
    animation-name: first-message;
  }
  .op-co-message2 {
    -webkit-animation-name: second-message;
    animation-name: second-message;
    opacity: 0;
  }
  .op-co-message3 {
    -webkit-animation-name: third-message;
    animation-name: third-message;
    opacity: 0;
  }

  @keyframes first-message {
    0%,
    45% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    50%,
    95% {
      -webkit-transform: translate3d(0, -32px, 0);
      transform: translate3d(0, -32px, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate3d(0, 32px, 0);
      transform: translate3d(0, 32px, 0);
      opacity: 0;
    }
  }

  @keyframes second-message {
    0%,
    45% {
      -webkit-transform: translate3d(0, 32px, 0);
      transform: translate3d(0, 32px, 0);
      opacity: 0;
    }
    50%,
    95% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translate3d(0, -32px, 0);
      transform: translate3d(0, -32px, 0);
      opacity: 0;
    }
  }

  // @keyframes first-message {
  //   0% {
  //     -webkit-transform: translate3d(0, 32px, 0);
  //     transform: translate3d(0, 32px, 0);
  //     opacity: 0;
  //   }
  //   5%,
  //   27% {
  //     -webkit-transform: translate3d(0, 0, 0);
  //     transform: translate3d(0, 0, 0);
  //     opacity: 1;
  //   }
  //   32%,
  //   100% {
  //     -webkit-transform: translate3d(0, -32px, 0);
  //     transform: translate3d(0, -32px, 0);
  //     opacity: 0;
  //   }
  // }

  // @keyframes second-message {
  //   0%,
  //   32% {
  //     -webkit-transform: translate3d(0, 32px, 0);
  //     transform: translate3d(0, 32px, 0);
  //     opacity: 0;
  //   }
  //   37%,
  //   59% {
  //     -webkit-transform: translate3d(0, 0, 0);
  //     transform: translate3d(0, 0, 0);
  //     opacity: 1;
  //   }
  //   64%,
  //   100% {
  //     -webkit-transform: translate3d(0, -32px, 0);
  //     transform: translate3d(0, -32px, 0);
  //     opacity: 0;
  //   }
  // }

  // @keyframes third-message {
  //   0%,
  //   64% {
  //     -webkit-transform: translate3d(0, 32px, 0);
  //     transform: translate3d(0, 32px, 0);
  //     opacity: 0;
  //   }
  //   69%,
  //   91% {
  //     -webkit-transform: translate3d(0, 0, 0);
  //     transform: translate3d(0, 0, 0);
  //     opacity: 1;
  //   }
  //   95%,
  //   100% {
  //     -webkit-transform: translate3d(0, -32px, 0);
  //     transform: translate3d(0, -32px, 0);
  //     opacity: 0;
  //   }
  // }

  .trust-pilot {
    iframe {
      margin-bottom: 0px;
    }
  }
`

const TrustPilotSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  iframe {
    margin-bottom: 0px;
  }
`

const DesktopTrustPilotSection = styled.div`
  width: 100%;

  @media (max-width: 550px) {
    display: none;
    display: block;
  }
`

const MobileTrustPilotSection = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 550px) {
    display: none;
    display: block;
    display: none;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  color: ${props => props.theme.colors.white.light};
  font-family: "GothamLight";
  color: rgba(255, 255, 255, 0.8);
  z-index: 5;
  // position: absolute;
  // top: 50%;
  // transform: translate(0px, -50%);
  // right: 0px;

  span {
    align-self: center;
  }

  @media (max-width: 1200px) {
    display: none !important;
  }
`

const LoginWrapper = styled.span`
  cursor: pointer;
`
const LinksMyAccount = ({ title, url, ...props }) => (
  <Link {...props} to={url}>
    <span>{title}</span>
  </Link>
)

const LinkSelectorWrapper = styled(LinksMyAccount)`
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-content: center;
  color: ${props => props.theme.colors.white.grey};
  background: #000;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  span {
    width: auto;
    text-transform: uppercase;
  }

  @media (max-width: 1200px) {
    padding: 5px 0px !important;
  }
`

const LinkWrapper = styled(LinkSelectorWrapper)`
  background: ${({ mobile }) => (mobile ? "transparent" : "#fff")};
  color: ${props => props.theme.colors.black.light};
  :hover {
    color: ${props => props.theme.colors.black.light};
    background-color: #eee;
  }
`

const LogoutButton = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-start;
  align-content: center;
  color: ${props => props.theme.colors.white.grey};
  /* background: #000; */
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 2px;
  padding: 8px 16px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  background: ${({ mobile }) => (mobile ? "transparent" : "#fff")};
  color: ${props => props.theme.colors.black.light};
  :hover {
    color: ${props => props.theme.colors.black.light};
    background-color: #eee;
  }

  @media (max-width: 1200px) {
    padding: 5px 0px;
  }
`

const PopoverWrapper = styled(Popover)`
  top: 27px !important;
  color: ${props => props.theme.colors.black.light};
  > :first-of-type {
    background-color: #fff !important;
  }
`

const DesktopBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    display: none;
  }
`

const RotatingBox = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 34px;
  text-transform: initial;

  @media (max-width: 550px) {
    display: flex;
  }
`

const FirstTextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const SecondTextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham Book";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

const TextLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Museo";
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  text-decoration: none !important;
  text-transform: none !important;
  color: ${props => props.theme.saleColor.textColor};
  cursor: pointer;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
    text-decoration: none;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`

const ListWrapper = styled.div``

const DiscountWrapperFr = () => (
  <TrustPilotSection>
    <DesktopTrustPilotSection>
      <MicroCombo
        language="fr"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </DesktopTrustPilotSection>
    <MobileTrustPilotSection>
      <MicroStar
        language="fr"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </MobileTrustPilotSection>
  </TrustPilotSection>
)

const DiscountWrapperEs = () => (
  <TrustPilotSection>
    <DesktopTrustPilotSection>
      <MicroCombo
        language="es"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </DesktopTrustPilotSection>
    <MobileTrustPilotSection>
      <MicroStar
        language="es"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </MobileTrustPilotSection>
  </TrustPilotSection>
)

const DiscountWrapperDe = () => (
  <TrustPilotSection>
    <DesktopTrustPilotSection>
      <MicroCombo
        language="de"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </DesktopTrustPilotSection>
    <MobileTrustPilotSection>
      <MicroStar
        language="de"
        culture="FR"
        theme="dark"
        width="100%"
        height="20px"
      />
    </MobileTrustPilotSection>
  </TrustPilotSection>
)

const DiscountWrapperEn = () => (
  <TrustPilotSection>
    <DesktopTrustPilotSection>
      <MicroCombo
        language="en"
        culture={
          process.env.GATSBY_CURRENCY_TYPE === "eur"
            ? "IE"
            : process.env.GATSBY_CURRENCY_TYPE === "usd"
            ? "US"
            : process.env.GATSBY_CURRENCY_TYPE === "gbp"
            ? "GB"
            : "CA"
        }
        theme="dark"
        width="100%"
        height="20px"
      />
    </DesktopTrustPilotSection>
    <MobileTrustPilotSection>
      <MicroStar
        language="en"
        culture={
          process.env.GATSBY_CURRENCY_TYPE === "eur"
            ? "IE"
            : process.env.GATSBY_CURRENCY_TYPE === "usd"
            ? "US"
            : process.env.GATSBY_CURRENCY_TYPE === "gbp"
            ? "GB"
            : "CA"
        }
        theme="dark"
        width="100%"
        height="20px"
      />
    </MobileTrustPilotSection>
  </TrustPilotSection>
)

const discountComponent = {
  fr: DiscountWrapperFr,
  es: DiscountWrapperEs,
  de: DiscountWrapperDe,
  en: DiscountWrapperEn,
}

const Bar = ({ intl, userContext, exclusifPromo }) => {
  const {
    profile,
    customerAccessToken,
    handleLogout,
    handleLogin,
    handleSignup,
    handleForgetPassword,
  } = userContext
  const isLoggedIn = Object.keys(profile).length !== 0
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  const handleOnClick = toggleModal
  const [isDropOpen, setisDropOpen] = useState(false)
  const [isHomePage, setIsHomePage] = useState(false)
  const [isShopPage, setIsShopPage] = useState(false)

  const { pathname } = useLocation()

  let DashboardUrl = intl.locale === "fr" ? "/mon-compte/" : "/dashboard/"

  let OrdersUrl = intl.locale === "fr" ? "commande/" : "orders/"

  let EditAddressUrl = intl.locale === "fr" ? "adresses/" : "edit-address/"

  let PaymentMethodsUrl =
    intl.locale === "fr" ? "moyens-de-paiement/" : "payment-methods/"

  let EditAccountUrl =
    intl.locale === "fr" ? "details-du-compte/" : "edit-account/"

  let ReferalsUrl = "parrainage/"

  const Component = discountComponent[intl.locale]

  const checkHomePage = () => {
    let currentPath = window.location.pathname
    let pathArr = currentPath.split("/")

    if (
      currentPath === "/" ||
      currentPath === `/${intl.locale}/` ||
      currentPath === `/${process.env.GATSBY_CURRENCY_TYPE}/` ||
      currentPath === `/${process.env.GATSBY_CURRENCY_TYPE}/${intl.locale}/`
    )
      setIsHomePage(true)
    else setIsHomePage(false)

    if (
      pathArr[pathArr.length - 1] === "shop" ||
      pathArr[pathArr.length - 2] === "shop"
    ) {
      setIsShopPage(true)
    } else setIsShopPage(false)
  }

  useEffect(() => {
    isBrowser && checkHomePage()
  }, [pathname, intl.locale])

  return (
    <Wrapper>
      <LeftWrapper />
      <CenterWrapper>
        {/* {process.env.GATSBY_CURRENCY_TYPE === "eur" && intl.locale === "fr" ? (
          // <TextLink to="/showroom-paris/?utm_medium=site&utm_source=header-noir&utm_campaign=ouverture-20241005">
          //   Ouverture exceptionnelle du showroom ce samedi ➔
          // </TextLink>
          <RotatingBox>
            <FirstTextBox className="opco-message op-co-message1">
              <StaticReviewSection section="header_section" />
            </FirstTextBox>
            <SecondTextBox className="opco-message op-co-message2">
              <TextLink to="/showroom-paris/?utm_medium=site&utm_source=header-noir&utm_campaign=ouverture-20241005">
                Ouverture exceptionnelle du showroom ce samedi ➔
              </TextLink>
            </SecondTextBox>
          </RotatingBox>
        ) : (
          <StaticReviewSection section="header_section" />
        )} */}
        {process.env.GATSBY_CURRENCY_TYPE === "eur" &&
        intl.locale === "fr" &&
        (isHomePage || isShopPage) ? (
          <>
            <DesktopBox>
              <StaticReviewSection section="header_section" />
            </DesktopBox>
            <RotatingBox>
              <FirstTextBox className="opco-message op-co-message1">
                <StaticReviewSection section="header_section" />
              </FirstTextBox>
              <SecondTextBox className="opco-message op-co-message2">
                {/* Payez en 2x, 3x ou 4x sans frais avec Alma */}
                <TextLink to="/showroom-paris/?utm_medium=site&utm_source=header-mobile&utm_campaign=showroom-paris-10e">
                  Essayez nos produits au showroom parisien
                </TextLink>
              </SecondTextBox>
            </RotatingBox>
          </>
        ) : (
          <StaticReviewSection section="header_section" />
        )}
        {/* <Component /> */}
        {/* <TrustPilotWidget /> */}
        {/* <StaticReviewSection section="header_section"/> */}
      </CenterWrapper>
      <PopoverWrapper
        disableReposition
        isOpen={isDropOpen}
        position={["bottom", "left"]}
        //   content={Languages}
        content={({ position, targetRect, popoverRect }) =>
          isLoggedIn && (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={"#fff"}
              arrowSize={7}
              arrowStyle={{ opacity: 1 }}
            >
              <ListWrapper
                // style={{ padding: "10px 0px" }}
                onMouseLeave={() => setisDropOpen(false)}
              >
                <LinkWrapper
                  title={intl.formatMessage({ id: "dashboard" })}
                  url={`${DashboardUrl}`}
                />
                <LinkWrapper
                  title={intl.formatMessage({ id: "orders" })}
                  url={`${DashboardUrl + OrdersUrl}`}
                />
                <LinkWrapper
                  title={intl.formatMessage({ id: "addresses" })}
                  url={`${DashboardUrl + EditAddressUrl}`}
                />
                {/* <LinkWrapper
                  title={intl.formatMessage({ id: "payment_method" })}
                  url={`${DashboardUrl + PaymentMethodsUrl}`}
                /> */}
                <LinkWrapper
                  title={intl.formatMessage({ id: "account_details" })}
                  url={`${DashboardUrl + EditAccountUrl}`}
                />
                {/* {process.env &&
                  process.env.GATSBY_ACTIVE_ENV === "eur" &&
                  intl.locale === "fr" &&
                  profile.email && (
                    <LinkWrapper
                      title={intl.formatMessage({ id: "referals" })}
                      url={`${DashboardUrl + ReferalsUrl}`}
                    />
                  )} */}
                <LogoutButton onClick={handleLogout}>
                  {intl.formatMessage({ id: "logout" })}
                </LogoutButton>
              </ListWrapper>
            </ArrowContainer>
          )
        }
        onClickOutside={() => setisDropOpen(false)}
      >
        <RightWrapper>
          {isLoggedIn ? (
            <Link
              onMouseEnter={() => setisDropOpen(true)}
              style={{
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                color: "#fff",
                textTransform: "uppercase",
              }}
              to={DashboardUrl}
            >
              {intl.formatMessage({ id: "loggedIn" })}
            </Link>
          ) : (
            <LoginWrapper onClick={handleOnClick}>
              {isLoggedIn
                ? profile.displayName.split("@")[0]
                : intl.formatMessage({ id: "login" })}
            </LoginWrapper>
          )}
          {!exclusifPromo && <LanguageSelector />}
          {!exclusifPromo && <CurrencySelector />}
        </RightWrapper>
      </PopoverWrapper>
      <LoginModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        handleLogin={handleLogin}
        handleSignup={handleSignup}
        forgetPassowrd={handleForgetPassword}
      />
    </Wrapper>
  )
}

export default withUserContext(injectIntl(Bar))
